import {useRouter} from "next/router";
import classNames from "classnames";
import axios from "axios";

import s from "./PreviewAlert.module.scss";

export default function PreviewAlert() {
  const router = useRouter();

  async function clearPreviewMode() {
    try {
      await axios.get("/api/exit-preview");
      router.push("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={classNames("custom-container", "py-3", s.main_div)}>
      <h5 className={classNames(s.preview_text)}>
        This page is a preview.{" "}
        <span className={classNames(s.link)} onClick={clearPreviewMode}>
          Click here
        </span>{" "}
        to exit preview mode.
      </h5>
    </div>
  );
}
