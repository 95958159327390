import Link from "next/link";
import classNames from "classnames";
import {TYPE_THEME} from "../../lib/constants";
import s from "./Footer.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Footer({className, footerContent, isDark, theme}) {
  let nestedFooterContent = footerContent[0].reference;

  let footerDisplay = [];
  for (let i = 0; i < nestedFooterContent.length; i++) {
    let section = nestedFooterContent[i];
    let sectionDisplay = [];
    sectionDisplay.push(
      <h5 className={s.section_title} key={`${i}-header`}>
        {section.footer_item}
      </h5>
    );
    for (let j = 0; j < section.reference.length; j++) {
      let referenceItem = section.reference[j];
      sectionDisplay.push(
        <Row className={s.link_row} key={`${i}-${j}`}>
          <Link href={`${referenceItem.url_slug}`} legacyBehavior={true}>
            <a
              target={referenceItem.is_new_tab ? "_blank" : "_self"}
              className={s.link_text}
            >
              {referenceItem.footer_item}
            </a>
          </Link>
        </Row>
      );
    }
    footerDisplay.push(
      <Col xs={12} sm={2} key={i}>
        <div className={s.col_main}>{sectionDisplay}</div>
      </Col>
    );
  }

  return (
    <div
      className={classNames(
        s.main_div,
        {[s.main_div_404]: isDark},
        {[s.light_theme]: theme === TYPE_THEME.LIGHT}
      )}
    >
      <Container className={classNames(className, "use-default", "mb-5")}>
        <Row className={classNames(s.footer_row, "justify-content-between")}>
          {footerDisplay}

          <Col xs={12} sm={2}>
            <div className={s.legal_div}>
              <p className={classNames(s.copyright, s.copyright_desktop)}>
                © {new Date().getFullYear()} Autonomy<br></br>
                <br></br>
                All rights reserved.<br></br>
                Vehicle Images by
                <Link href="https://www.evoximages.com" legacyBehavior={true}>
                  <a className={classNames(s.copyright_link)} rel="nofollow">
                    {` `}EVOXImages.com
                  </a>
                </Link>
              </p>

              <p className={classNames(s.copyright, s.copyright_mobile)}>
                © {new Date().getFullYear()} Autonomy<br></br>
                All rights reserved.<br></br>
                <br></br>
                Vehicle Images by <br></br>
                <Link href="https://www.evoximages.com" legacyBehavior={true}>
                  <a className={classNames(s.copyright_link)} rel="nofollow">
                    {` `}EVOXImages.com
                  </a>
                </Link>
              </p>

              <Link href={`/privacy`} legacyBehavior={true}>
                <a>
                  <p className={s.privacy_link}>Privacy Policy</p>
                </a>
              </Link>

              <Link href={`/tos`} legacyBehavior={true}>
                <a>
                  <p className={s.privacy_link}>Terms of Service</p>
                </a>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
