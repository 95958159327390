import Head from "next/head";
import {useRouter} from "next/router";
import Layout, {siteTitle} from "../hocs/Layout";
import {getPageByTitle, getCollectionByTitle} from "../lib/butter-api";
import {revalidateBySeconds} from "/utils/page";

import Custom404Template from "../components/Custom404/Custom404Template";
import {TYPE_THEME} from "../lib/constants";

export default function Custom404({
  pageContent,
  navContent,
  preview,
  vehicle,
  refundableModalContent,
}) {
  const seo = pageContent.fields.seo;
  const {pathname} = useRouter();

  return (
    <Layout
      navContent={navContent}
      theme={TYPE_THEME.DARK}
      preview={preview}
      vehicle={vehicle}
      refundableModalContent={refundableModalContent}
    >
      <Head>
        <title>{seo.title ? seo.title : siteTitle}</title>

        {seo.meta_description && (
          <meta name="description" content={seo.meta_description} />
        )}

        {seo.opengraph_title && (
          <meta property="og:title" content={seo.opengraph_title} />
        )}

        <meta
          property="og:url"
          content={`https://www.autonomy.com/beta-thank-you`}
        />

        {seo.opengraph_image && (
          <meta property="og:image" content={seo.opengraph_image} />
        )}

        {seo.opengraph_description && (
          <meta property="og:description" content={seo.opengraph_description} />
        )}

        <meta
          property="og:url"
          content={`https://www.autonomy.com${pathname}`}
        />
      </Head>

      <Custom404Template pageContent={pageContent} />
    </Layout>
  );
}

export async function getStaticProps({preview = null}) {
  const vehicle = {
    make: "Tesla",
    model: "Model 3",
    price: 900,
  };

  const pageContent = (await getPageByTitle("custom-404", preview)) || [];
  const refundableModalContent =
    (await getPageByTitle("refundable-modal", preview)) || [];

  const navContent = {
    navContent: (await getCollectionByTitle("main_menu_-_main", preview)) || [],
    footerContent: (await getCollectionByTitle("footer_-_main", preview)) || [],
    pageSlug: "404",
  };

  return {
    props: {pageContent, navContent, preview, vehicle, refundableModalContent},
    revalidate: revalidateBySeconds(900),
  };
}
