import {useRouter} from "next/router";
import Head from "next/head";
import classNames from "classnames";

import s from "./Layout.module.scss";

import Navbar from "../components/Navbar/NavbarReimagined";
import PreviewAlert from "../components/PreviewAlert/PreviewAlert";
import Footer from "../components/Footer/Footer";
import FixedBotCTA from "../components/Shared/FixedBotCTA/FixedBotCTA";
import ButterCMSContentContext from "../components/context/butterCMSContent";
import {TYPE_THEME} from "../lib/constants";

export const siteTitle = "The New Way of Getting Your Next Car | Autonomy©";

export default function Layout({
  className,
  navContent,
  hideNav,
  isDark,
  preview,
  children,
  showPromoBanner,
}) {
  const {pathname} = useRouter();

  return (
    <ButterCMSContentContext.Provider value={{navContent}}>
      <div className={classNames(className, s.main_div)}>
        <Head>
          <link rel="icon" href="/favicon.ico" />
          <meta
            name="description"
            content="The radically new way to get your next car. Month-to-month car subscriptions at affordable all-in prices."
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="The radically new way to get your next car. Month-to-month car subscriptions at affordable all-in prices."
          />
          <meta
            property="og:url"
            content={`https://www.autonomy.com${pathname}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="apple-itunes-app" content="app-id=1568544395" />
        </Head>
        {navContent && (
          <Navbar
            navContent={navContent}
            hideNav={hideNav}
            theme={isDark ? TYPE_THEME.DARK : TYPE_THEME.LIGHT}
            showPromoBanner={showPromoBanner}
          />
        )}

        {preview && <PreviewAlert />}

        <FixedBotCTA />

        <main style={{overflowX: "hidden"}}>{children}</main>

        {navContent && (
          <Footer footerContent={navContent.footerContent} isDark={isDark} />
        )}
      </div>
    </ButterCMSContentContext.Provider>
  );
}
